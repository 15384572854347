import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'

import { GENERATOR_INPUT_TYPE, REGEX } from '@constants'
import { ObjectFieldDTO } from '@types'

import {
  BIND_TYPE_INPUTS_FORM_TYPE,
  PageContext,
  SegmentFieldType,
  SegmentType,
} from '@gantt/components/GanttCreateOrEdit'
import { RESOURCE_KEY_FIELD_PREFIX, RESOURCE_LINK_FIELD_PREFIX } from '@gantt/constants'

import { BaseFormFields, SegmentLabelsFields } from '../../components'

//todo actual?
type Props = {
  type: SegmentType
  objectFieldsForValidation?: ObjectFieldDTO[]
}

export const SegmentItem: FC<Props> = () => {
  const { t } = useTranslation()
  const { watch, setValue, resetField, setError, clearErrors } = useFormContext()
  const [isShowScriptDialog, setIsShowScriptDialog] = useState(false)
  const { watchedObjectResource, modalType, currentBarObject, currentBarIndex } =
    useContext(PageContext)

  const watchResourceKeyField = watch(`link.resourceKeyField`)
  const watchSystemName = watch(`link.systemName`)
  const watchResourceLinkField = watch(`link.resourceLinkField`)
  const watchLinkAxisX = watch(`link.axisX`)
  const watchLinkAxisY = watch(`link.axisY`)

  const isNoBarObject = !currentBarObject

  useEffect(() => {
    if (watchResourceKeyField !== `${RESOURCE_KEY_FIELD_PREFIX}${watchSystemName}`) {
      !watchSystemName
        ? resetField(`link.resourceKeyField`)
        : setValue(`link.resourceKeyField`, `${RESOURCE_KEY_FIELD_PREFIX}${watchSystemName}`)
    }

    if (watchResourceLinkField !== `${RESOURCE_LINK_FIELD_PREFIX}${watchSystemName}`) {
      !watchSystemName
        ? resetField(`link.resourceLinkField`)
        : setValue(`link.resourceLinkField`, `${RESOURCE_LINK_FIELD_PREFIX}${watchSystemName}`)
    }
  }, [watchSystemName])

  const toggleOpenScriptValueDialog = useCallback(() => {
    setIsShowScriptDialog(prevState => !prevState)
  }, [])

  const handleOnErrorLinks = () => {
    setError(`link.axisX.field.pathStr`, { type: 'custom', message: '' })
    setError(`link.axisY.field.pathStr`, { type: 'custom', message: '' })
  }

  return (
    <Box p={2}>
      <FormInput
        disabled={modalType === 'edit'}
        inputType={GENERATOR_INPUT_TYPE.INPUT}
        label={t('ganttCreate.timelineForm.systemName')}
        name={`link.systemName`}
        placeholder={t('ganttCreate.timelineForm.systemName')}
        replacePattern={REGEX.MODEL_CODE_REPLACE_PATTERN}
        rules={{
          required: true,
          validate: value => value.trim().length !== 0,
        }}
      />
      <Box component='fieldset' mb={1}>
        <legend>{t('ganttCreate.objectLinks')}</legend>
        {/*  WF-2052 выделять обьект ссылки}
        {/*<GanttSegmentLinksHelper*/}
        {/*  linkAxisX={watchLinkAxisX}*/}
        {/*  linkAxisY={watchLinkAxisY}*/}
        {/*  onErrorLinks={handleOnErrorLinks}*/}
        {/*/>*/}
        <BindTypeInputs
          bindTypeOptions={[]}
          blockLabel={t('ganttCreate.timelineForm.segmentAxisYKey')}
          containerName='link.axisY'
          formType={BIND_TYPE_INPUTS_FORM_TYPE.RESOURCE}
          isDisabled={!watchedObjectResource}
          // WF-3071 Фильтр селектов по типу
          // optionsFilter={keyFieldOptionsFilter}
          watchedObject={watchedObjectResource}
        />
        <div style={{ width: 50 }} />
        <BindTypeInputs
          hasCommands
          bindTypeOptions={[]}
          blockLabel={t('ganttCreate.timelineForm.segmentAxisXKey')}
          containerName='link.axisX'
          formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
          isDisabled={isNoBarObject}
          // WF-3071 Фильтр селектов по типу
          // optionsFilter={keyFieldOptionsFilter}
          watchedObject={currentBarObject}
        />
        <div hidden>
          <FormInput
            disabled
            readOnly
            inputType={GENERATOR_INPUT_TYPE.INPUT}
            label={t('ganttCreate.resourceField')}
            name={`link.resourceKeyField`}
          />
        </div>
      </Box>
      <BindTypeInputs
        fieldsetMarkup
        hasCommands
        bindTypeOptions={[]}
        containerName='datetimeStart'
        fieldsetTitle={t('ganttCreate.timelineForm.dateTimeStart')}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        isDisabled={isNoBarObject}
        // WF-3071 Фильтр селектов по типу
        // optionsFilter={dateTimeFieldOptionsFilter}
        prefix={`bar_${currentBarIndex}_segment_${watchSystemName}_${SegmentFieldType.DATETIME_START}`}
        watchedObject={currentBarObject}
      />
      <BindTypeInputs
        fieldsetMarkup
        hasCommands
        bindTypeOptions={[]}
        containerName='datetimeEnd'
        fieldsetTitle={t('ganttCreate.timelineForm.dateTimeEnd')}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        isDisabled={isNoBarObject}
        // WF-3071 Фильтр селектов по типу
        // optionsFilter={dateTimeFieldOptionsFilter}
        prefix={`bar_${currentBarIndex}_segment_${watchSystemName}_${SegmentFieldType.DATETIME_END}`}
        watchedObject={currentBarObject}
      />
      <BindTypeInputs
        fieldsetMarkup
        bindTypeOptions={[]}
        containerName='duration'
        fieldsetTitle={t('ganttCreate.timelineForm.duration')}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        isDisabled={isNoBarObject}
        // WF-3071 Фильтр селектов по типу
        // optionsFilter={durationFieldOptionsFilter}
        watchedObject={currentBarObject}
      />
      <BaseFormFields
        currentBarKey={`bar_${currentBarIndex}`}
        disabled={isNoBarObject}
        isShowScriptDialog={isShowScriptDialog}
        keyValue={`segment_${watchSystemName}`}
        toggleOpenScriptValueDialog={toggleOpenScriptValueDialog}
        watchedObject={currentBarObject}
      />
      <SegmentLabelsFields watchedObject={currentBarObject} />
    </Box>
  )
}
