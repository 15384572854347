import i18next from 'i18next'

import { checkAvailableVariablesInScript, ErrorValidJSType } from '@helpers'
import { ErrorCatch } from '@types'

import { JS_FUN_ARGS } from '@gantt/constants'
import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

type EC = ErrorCatch

export const checkValidGanttJS = (
  value: string,
  variables?: Record<string, string[]>,
  returnValueType?: GANTT_SCRIPT_RETURN_TYPE,
  keys?: string[]
): ErrorValidJSType | undefined => {
  if (!value) {
    return { error: false }
  }

  if (!value.includes('return')) {
    return { error: true, message: `${i18next.t('error.functionReturn')}` }
  }

  const notAvailableVarsError = checkAvailableVariablesInScript(value, variables)

  if (notAvailableVarsError) {
    return notAvailableVarsError
  }

  try {
    const runScript = new Function(
      JS_FUN_ARGS.mainData,
      JS_FUN_ARGS.restData,
      JS_FUN_ARGS.metaData,
      value
    )

    // WF-3173 Проверка на возвращаемоезначение скрипта
    // const returnedValue = runScript({}, {}, { timezone: '', dateFormatFn: () => '' })

    runScript(
      {},
      {},
      {
        timezone: '',
        dateFormatFn: () => '',
      }
    )
    // const returnedValueTypeError = checkReturnedValueType(returnedValue, returnValueType)
    //
    // if (returnedValueTypeError) {
    //   return returnedValueTypeError
    // }
    //
    // const returnedValueObjectKeysError = checkAvailableKeyInArrayOfObjects(returnedValue, keys)
    //
    // if (returnedValueObjectKeysError) {
    //   return returnedValueObjectKeysError
    // }

    return undefined
  } catch (e: EC) {
    return { error: true, message: e?.message || `${i18next.t('error.valueJS')}` }
  }
}
