import { ErrorValidJSType } from '@helpers'

import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

export const checkValidGanttJSON = (
  value: string,
  returnValueType?: GANTT_SCRIPT_RETURN_TYPE,
  keys?: string[]
): ErrorValidJSType | undefined => {
  let parsedValue = null

  try {
    parsedValue = JSON.parse(value)
  } catch (error) {
    console.log('error', error)
  }

  // WF-3173 Проверка на возвращаемоезначение скрипта

  // const returnedValueTypeError = checkReturnedValueType(parsedValue, returnValueType)
  //
  // if (returnedValueTypeError) {
  //   return returnedValueTypeError
  // }
  //
  // const returnedValueObjectKeysError = checkAvailableKeyInArrayOfObjects(parsedValue, keys)
  //
  // if (returnedValueObjectKeysError) {
  //   return returnedValueObjectKeysError
  // }

  if (!value) {
    return { error: false }
  }

  return
}
