import { AvailableVariables } from '@gantt/components/GanttCreateOrEdit/types'

export enum JS_FUN_ARGS {
  mainData = 'mainData',
  restData = 'restData',
  metaData = 'metaData',
}

export const COMMON_META_DATA = { [JS_FUN_ARGS.metaData]: ['timezone', 'dateFormatFn'] }

export const HEADER_MAIN_DATA = { [JS_FUN_ARGS.mainData]: ['field'] }

export const initialAvailableVariables = (): AvailableVariables => ({
  [JS_FUN_ARGS.mainData]: [],
  [JS_FUN_ARGS.metaData]: [],
  [JS_FUN_ARGS.restData]: [],
})
