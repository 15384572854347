import { ActionData } from '@microservices/gantt-react-component'
import { TypedUseMutationResult } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { QueryStatus } from '@reduxjs/toolkit/query'

import { dispatchSelfUpdate } from '@pages/ConfiduredGantt/customEvent'

import { getParamsStringSafe } from '@helpers'

import { AXIS_TYPE, AxisDefinedData, GanttFormValues } from './types'

type GetAxisData = Record<
  AXIS_TYPE | 'undefined',
  (actionData: ActionData, ganttConfig: GanttFormValues) => AxisDefinedData
>

export const getAxisData: GetAxisData = {
  X(actionData: ActionData, ganttConfig: GanttFormValues): AxisDefinedData {
    const timeline = ganttConfig.timeline.find(tml => tml.data?.id === actionData?.data?.objectCode)
    const key = timeline ? getParamsStringSafe(timeline?.key) : ''

    return {
      ...(actionData?.data || null),
      objectCode: actionData?.data?.objectCode,
      objectId: actionData?.data?.[key],
      configId: ganttConfig.id!,
    }
  },
  Y(actionData: ActionData, ganttConfig: GanttFormValues): AxisDefinedData {
    const key = getParamsStringSafe(ganttConfig.resource.key)
    const place = actionData?.metaData?.place || ''

    return {
      ...(actionData?.data?.[place] || null),
      objectCode: ganttConfig?.resource?.data?.id || '',
      objectId: actionData?.data?.[place]?.[key],
      configId: ganttConfig.id!,
    }
  },
  undefined(): AxisDefinedData {
    console.warn('there is no definition axis for action')

    return {
      objectCode: '',
      objectId: '',
      configId: '',
    }
  },
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const stopPropagation = (data: ActionData) => data?.metaData?.event?.stopPropagation?.()

type Response = Pick<TypedUseMutationResult<any, any, any>, 'originalArgs' | 'status'>

export const isDone = (response?: Response) =>
  response?.status && [QueryStatus.fulfilled, QueryStatus.rejected].includes(response.status)

export const isFulfilled = (response?: Response) => response?.status === QueryStatus.fulfilled

export const processResponse = (response: Response) =>
  isDone(response) && response?.originalArgs && dispatchSelfUpdate(response.originalArgs)
