import { useMemo } from 'react'
import { ActionData, DataXKeys } from '@microservices/gantt-react-component'

import { ACTION_CODE, EVENT_CODE_GANTT } from '@constants'

import {
  AXIS_TYPE,
  BindField,
  ContextMenuAction,
  GANTT_BIND_TYPE_LIST,
  GanttActionType,
  GanttFormValues,
} from '../types'

import { useRunAction } from './useRunAction'

type HandlerMap = {
  onDoubleBoxClick?: ((params: ActionData) => void)[]
  onDoubleRowXClick: ((params: ActionData) => void)[]
  onDoubleRowYClick: ((params: ActionData) => void)[]
  onDoubleCellClick: ((params: ActionData) => void)[]
  onBoxResize: ((params: ActionData) => void)[]
  onBoxMove: ((params: ActionData) => void)[]
  onContextMenuItemClick: ((params: ActionData) => void) | undefined
}

export const useGetHandlerMap = (ganttConfig: GanttFormValues, routePath: string) => {
  const runAction = useRunAction(ganttConfig, routePath)

  return useMemo(() => {
    const handlers: HandlerMap = {
      onDoubleBoxClick: [],
      onDoubleRowXClick: [],
      onDoubleCellClick: [],
      onDoubleRowYClick: [],
      onBoxResize: [],
      onBoxMove: [],
      onContextMenuItemClick: undefined,
    }

    ganttConfig?.timeline?.forEach(timelineItem => {
      timelineItem?.actions?.forEach(action => {
        const runActionX = (callback_data: ActionData, isExecuted = true) => {
          if (isExecuted && isObjectCodeExist(callback_data)) {
            runAction(action, callback_data, AXIS_TYPE.X)
          }
        }

        const isObjectCodeExist = (data: ActionData) =>
          data?.data?.objectCode === timelineItem.data?.id

        const isTargetBacklog = (data: ActionData) =>
          data?.metaData?.target === DataXKeys.BacklogData

        const isEqualTargetSource = (data: ActionData) =>
          data?.metaData?.source === data?.metaData?.target

        switch (action.eventCode) {
          case EVENT_CODE_GANTT.ON_SEGMENT_CLICK:
            handlers.onDoubleBoxClick?.push(data =>
              runActionX(data, data.config?.link?.systemName === action.target)
            )

            break
          case EVENT_CODE_GANTT.ON_BAR_CLICK:
            handlers.onDoubleBoxClick?.push(data => runActionX(data))

            break
          case EVENT_CODE_GANTT.ON_ROW_CLICK:
            handlers.onDoubleRowXClick.push(data => runActionX(data))

            break
          case EVENT_CODE_GANTT.ON_SEGMENT_RESIZE:
          case EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_RESIZE:
            handlers.onBoxResize.push(data => runActionX(data))

            break
          case EVENT_CODE_GANTT.ON_SEGMENT_INNER_MOVE:
            handlers.onBoxMove.push(data =>
              runActionX(data, isEqualTargetSource(data) && !isTargetBacklog(data))
            )

            break
          case EVENT_CODE_GANTT.ON_SEGMENT_OUTER_MOVE:
            handlers.onBoxMove.push(data =>
              runActionX(data, !isEqualTargetSource(data) && isTargetBacklog(data))
            )

            break
          case EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_INNER_MOVE:
            handlers.onBoxMove.push(data =>
              runActionX(data, isEqualTargetSource(data) && isTargetBacklog(data))
            )

            break
          case EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_OUTER_MOVE:
            handlers.onBoxMove.push(data =>
              runActionX(data, !isEqualTargetSource(data) && !isTargetBacklog(data))
            )

            break
        }
      })
    })

    ganttConfig?.resource?.actions?.forEach(action => {
      const runActionY = (callback_data: ActionData, isExecuted = true) => {
        if (isExecuted) {
          runAction(action, callback_data, AXIS_TYPE.Y)
        }
      }

      switch (action.eventCode) {
        case EVENT_CODE_GANTT.ON_ROW_CLICK:
          handlers.onDoubleRowYClick.push(data => runActionY(data))

          break
        case EVENT_CODE_GANTT.ON_CELL_CLICK:
          handlers.onDoubleCellClick.push(data =>
            runActionY(data, action.target === data?.data?.colDef.headerName)
          )

          break
      }
    })

    handlers.onContextMenuItemClick = actionData => {
      const menuItem: ContextMenuAction = actionData?.metaData?.menuItem
      const command = menuItem.command || ''
      const commands = menuItem.commands
      const axis = actionData?.metaData?.axis

      if (!command && !commands) {
        return
      }

      const actionField: BindField = commands
        ? {
            bindType: GANTT_BIND_TYPE_LIST.COMMANDS,
            commands,
          }
        : {
            bindType: GANTT_BIND_TYPE_LIST.COMMAND,
            command,
          }

      const contextMenuAction: GanttActionType = {
        actionCode: ACTION_CODE.EXECUTE_COMMAND,
        actionField,
        code: menuItem.name,
        eventCode:
          axis === AXIS_TYPE.X
            ? EVENT_CODE_GANTT.ON_BAR_RIGHT_CLICK
            : EVENT_CODE_GANTT.ON_ROW_RIGHT_CLICK,
        id: 0,
        title: menuItem.name,
      }

      runAction(contextMenuAction, actionData, axis!)
    }

    return handlers
  }, [ganttConfig])
}
