import { EmptyObject } from '@reduxjs/toolkit'

import { getEnrichedParams, transformResponseWithPagination } from '@helpers'
import {
  ExtendedExecuteCommand,
  FetchGanttChartBacklogDataArgs,
  FetchGanttChartBacklogDataResponse,
  FetchGanttChartConfigArgs,
  FetchGanttChartDataArgs,
  FetchGanttChartDataResponse,
  FetchGanttChartDataTransformResponse,
  FetchTimeLineDataArgs,
  GanttType,
  ItemToplogDataResponse,
  MoveBacklogGanttBoxArgs,
  MoveGanttBoxArgs,
  ResizeGanttBoxArgs,
} from '@types'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit'

import { commonAPI } from './common.api'

export const ganttChartApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchGanttChartData: build.query<FetchGanttChartDataTransformResponse, FetchGanttChartDataArgs>(
      {
        query: ({ sort, filters, configId, ...rest }) => ({
          url: `/gantChart/data/${configId}?${getEnrichedParams(
            ['sort', 'filters'],
            [sort || undefined, filters || undefined]
          )}`,
          params: rest,
        }),
        transformResponse: transformResponseWithPagination<FetchGanttChartDataResponse>,
      }
    ),
    fetchGanttChartBacklog: build.query<
      FetchGanttChartBacklogDataResponse,
      FetchGanttChartBacklogDataArgs
    >({
      query: ({ filters, configId, ...rest }) => ({
        url: `/gantChart/backlog/${configId}?${getEnrichedParams(
          ['filters'],
          [filters || undefined]
        )}`,
        params: rest,
      }),
    }),
    fetchGanttChartConfig: build.query<GanttFormValues, FetchGanttChartConfigArgs>({
      query: ({ configId, ...rest }) => ({
        url: `/gantChart/config/${configId}`,
        params: rest,
      }),
    }),
    fetchGanttChart: build.query<GanttType[], EmptyObject>({
      query: () => ({
        url: `/gantChart/all`,
      }),
    }),
    fetchTimeLineData: build.mutation<ItemToplogDataResponse, FetchTimeLineDataArgs>({
      query: ({ configId, objectId, filters, objectCode }) => ({
        url: `/gantChart/${configId}/timeline/${objectId}?${getEnrichedParams(
          ['filters'],
          [filters || undefined]
        )}`,
        method: 'GET',
        params: { objectCode },
      }),
    }),
    moveGanttBacklogBox: build.mutation<void, MoveBacklogGanttBoxArgs>({
      query: ({ configId, objectId, objectCode, resourceIdName }) => ({
        url: `/gantChart/data/resourceShift/backlog/${configId}`,
        method: 'POST',
        params: { objectId, objectCode, resourceIdName },
      }),
    }),
    resizeGanttBox: build.mutation<void, ResizeGanttBoxArgs>({
      query: ({ configId, objectId, objectCode, timeValues }) => ({
        url: `/gantChart/data/timeShift/${configId}`,
        method: 'POST',
        params: { objectId, objectCode },
        body: timeValues,
      }),
    }),
    moveGanttBox: build.mutation<void, MoveGanttBoxArgs>({
      query: ({ configId, objectId, objectCode, resourceIdValue, resourceIdName }) => ({
        url: `/gantChart/data/resourceShift/${configId}`,
        method: 'POST',
        params: { objectId, objectCode, resourceIdValue, resourceIdName },
      }),
    }),
    executeCommand: build.mutation<void, ExtendedExecuteCommand>({
      query: ({ command, objectCode, objectId, ...body }) => ({
        url: `/ui/command/${objectCode}/${command}`,
        method: 'POST',
        params: { objectId, objectCode },
        body,
      }),
    }),
  }),
})

export const {
  useMoveGanttBacklogBoxMutation,
  useResizeGanttBoxMutation,
  useMoveGanttBoxMutation,
  useFetchGanttChartDataQuery,
  useFetchGanttChartConfigQuery,
  useFetchGanttChartBacklogQuery,
  useFetchGanttChartQuery,
  useFetchTimeLineDataMutation,
  useExecuteCommandMutation,
} = ganttChartApi
