import { useCallback, useEffect } from 'react'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import { ActionData } from '@microservices/gantt-react-component'

import { processResponse } from '@pages/ConfiduredGantt/utils'

import { useExecuteCommandMutation } from '@redux/api/ganttChart.api'

import { getParamsStringSafe } from '@helpers'
import { EntityCommandDTO } from '@types'

import { AxisDefinedData, GanttActionType, GanttFormValues } from '../types'

export const useExecuteCommand = (ganttConfig: GanttFormValues) => {
  const [executeCommand, response] = useExecuteCommandMutation()

  useEffect(() => {
    processResponse(response)
  }, [response?.status])

  return useCallback(
    (action: GanttActionType, actionData: ActionData, definedData: AxisDefinedData) => {
      const command = getParamsStringSafe(action.actionField)

      if (!isEmpty(command) && isArray(command)) {
        command.map((item: EntityCommandDTO) => {
          let preparedData = definedData

          if (item.prepareScript) {
            const runScript = new Function('data', item.prepareScript)

            preparedData = runScript(definedData)
          }

          return executeCommand({
            ...preparedData,
            command: item.name,
          })
        })

        return true
      }

      executeCommand({
        ...definedData,
        command,
      })

      return true
    },
    [ganttConfig?.code]
  )
}
