import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: {
  title: string
} = {
  title: '',
}

const appFooter = createSlice({
  name: 'appFooter',
  initialState,
  reducers: {
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload
    },
    resetTitle(state) {
      state.title = ''
    },
  },
})

export default appFooter.reducer
export const { setTitle, resetTitle } = appFooter.actions
