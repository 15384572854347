import { ComponentType, FC, lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import isUndefined from 'lodash/isUndefined'
import moment from 'moment'
import {
  ActionData,
  DataX,
  DataY,
  GantChartProps,
  ReqDateEdges,
  ScrollData,
} from '@microservices/gantt-react-component'
import { LinearProgress } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'
import { GridSortDirection } from '@mui/x-data-grid/models/gridSortModel'
import { IMessage } from '@stomp/stompjs'

import { isFulfilled } from '@pages/ConfiduredGantt/utils'

import {
  useFetchGanttChartBacklogQuery,
  useFetchGanttChartConfigQuery,
  useFetchGanttChartDataQuery,
  useFetchTimeLineDataMutation,
} from '@redux/api/ganttChart.api'
import { resetTitle, setTitle } from '@redux/reducers/appFooter.reducer'
import { showMessage } from '@redux/reducers/snackbar.reducer'

import { useAppDispatch, usePaletteMode, useStateless, useSubscription } from '@hooks'
import { getParamsStringSafe } from '@helpers'
import { EVENT_NAME_OBJECT_MESSAGE } from '@constants'
import { FetchGanttChartDataArgs, MessageGanttBody } from '@types'

import { useGetAvailableMovesAndResizes } from './hooks/useGetAvailableMovesAndResizes'
import { useGetHandlerMap } from './hooks/useGetHandlerMap'
import { usePrepareConfigActions } from './hooks/usePrepareConfigActions'
import { DetailSelfUpdate, GanttEvents } from './customEvent'
import { AXIS_TYPE, COMMAND, ContextMenuAction, GanttFormValues, TimelineItem } from './types'

const GantChartWithProvider = lazy<ComponentType<GantChartProps>>(() =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('@microservices/gantt-react-component').then(({ GantChartWithProvider }) => ({
    default: GantChartWithProvider,
  }))
)

export type ConfiguredGanttProps = {
  entityId: string
  path: string
  title: string
  timezone: string | null
}

export const ConfiguredGantt: FC<ConfiguredGanttProps> = props => {
  const { entityId: configId, path, timezone, title } = props
  const dispatch = useAppDispatch()
  const theme = usePaletteMode()
  const { t } = useTranslation()

  const [enterDate] = useState<number>(Date.now())
  const [dynamicDataX, setDynamicDataX] = useState<DataX[]>([])
  const [dynamicDataY, setDynamicDataY] = useState<DataY[]>([])
  const [dynamicDataBacklog, setDynamicDataBacklog] = useState<DataX[]>([])
  const [deleteIds, setDeleteIds] = useState<string[]>([])

  const [filters, setFilters] = useState<string | undefined>(undefined)
  const [sort, setSort] = useState<string[] | undefined>(undefined)
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>()
  const [objectToSort, setObjectToSort] = useState<string | undefined>(undefined)
  // NOTE сортировка в другом формате, необходимая для установки состояния сортировки компонента

  const [nextPageSize, setNextPageSize] = useState<number | undefined>(undefined)

  const [nextVerticalPage, setNextVerticalPage] = useState<number>(0)
  const [currentVerticalPage, setCurrentVerticalPage] = useState<number>(0)

  const [nextTime, setNextTime] = useState<ReqDateEdges | undefined>(undefined)
  const [currentTime, setCurrentTime] = useState<ReqDateEdges | undefined>(undefined)

  const [isInited, setIsInited] = useState(false)
  const [isInitedBacklog, setIsInitedBacklog] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const [getGantBox] = useFetchTimeLineDataMutation()

  const isDefinedNecessary = [
    configId,
    objectToSort,
    sort,
    filters,
    nextTime?.start,
    nextTime?.end,
    nextPageSize,
  ].every(value => !isUndefined(value))

  const configResponse = useFetchGanttChartConfigQuery(
    {
      configId,
      ui_time: enterDate,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !configId,
    }
  )

  const ganttConfig: GanttFormValues | null = configResponse?.data || null
  const timelineConfig = ganttConfig?.timeline
  const resourceConfig = ganttConfig?.resource
  const resourceId = resourceConfig?.data?.id

  const getParams = ({
    page = 0,
    ui_desc = 'init_toplog',
  }): FetchGanttChartDataArgs & { ui_desc: string; ui_time: number } => ({
    configId,
    stopTime: nextTime?.end,
    startTime: nextTime?.start,
    page,
    size: nextPageSize,
    sort,
    objectToSort,
    filters,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore для лога запроса
    ui_desc,
    ui_time: enterDate,
  })

  const getBacklogParams = ({ ui_desc = 'init_backlog' }) => {
    return getParams({ ui_desc, page: 0 }) //page/size?...
  }

  //todo check pagination
  const initBacklogResponse = useFetchGanttChartBacklogQuery(getBacklogParams({}), {
    refetchOnMountOrArgChange: true,
    skip: isInitedBacklog || !isDefinedNecessary || !ganttConfig?.canvas?.hasBacklog,
  })

  const dynamicBacklogResponse = useFetchGanttChartBacklogQuery(
    getBacklogParams({ ui_desc: 'next_backlog' }),
    {
      refetchOnMountOrArgChange: true,
      skip: !isInitedBacklog || !isDefinedNecessary || !ganttConfig?.canvas?.hasBacklog,
    }
  )

  const initialPageResponse = useFetchGanttChartDataQuery(getParams({}), {
    refetchOnMountOrArgChange: true,
    skip: isInited || !isDefinedNecessary,
  })

  const verticalPageResponse = useFetchGanttChartDataQuery(
    getParams({ page: nextVerticalPage, ui_desc: 'vertical_toplog' }),
    {
      refetchOnMountOrArgChange: true,
      skip: !isInited || !isDefinedNecessary || nextVerticalPage === currentVerticalPage,
    }
  )

  const horizontalPageResponse = useFetchGanttChartDataQuery(
    getParams({ page: currentVerticalPage, ui_desc: 'horizontal_toplog' }),
    {
      skip:
        !isInited ||
        !isDefinedNecessary ||
        !(currentTime?.end || currentTime?.start) ||
        (nextTime?.end === currentTime?.end && nextTime?.start === currentTime?.start),
    }
  )

  const setDefaultSort = () => {
    if (isUndefined(configResponse?.data?.sort?.value)) {
      return
    }

    const { code, value, axis } = configResponse?.data?.sort || {}
    const objectToSort = code || (axis === AXIS_TYPE.Y ? resourceId : '')

    const sortVal = value as
      | Array<{
          field: string
          order: string
        }>
      | undefined
    const sortModel = sortVal
      ?.map(item => ({
        field: item.field,
        sort: item.order as GridSortDirection,
      }))
      .filter(item => !!item.field)
    const sortQuery = sortModel
      ?.filter(item => item.field && item.sort)
      .map(item => `${item.field},${item.sort}`)

    setSort(sortQuery)
    setSortModel(sortModel)
    setObjectToSort(objectToSort)
  }

  const resetState = () => {
    setFilters(undefined)
    setSort(undefined)
    setSortModel(undefined)
    setObjectToSort(undefined)

    setNextPageSize(undefined)
    setCurrentVerticalPage(0)
    setNextVerticalPage(0)
    setCurrentTime(undefined)
    setNextTime(undefined)

    setDynamicDataX([])
    setDynamicDataY([])

    setIsFetching(false)
    setIsInited(false)
    setIsInitedBacklog(false)
  }

  const preparedConfig = usePrepareConfigActions(ganttConfig)

  const resourceKeyFields = useMemo(
    () => timelineConfig?.flatMap(tm => tm.sections.map(section => section.link.resourceKeyField)),
    [timelineConfig]
  )

  const statelessResourceKeyFields = useStateless(resourceKeyFields)

  //NOTE: временное решения / ожидаются готовые данные не требующие фильтрацию
  const initDataX = useMemo(() => {
    const dataX = initialPageResponse?.data?.data?.timelineData
    const filtered = dataX?.filter(item =>
      statelessResourceKeyFields.current?.some(key => key in item)
    )

    dataX?.length &&
      console.info('CHECK GANTT TOP DATA', {
        come: dataX?.length,
        shown: filtered?.length,
      })

    return filtered
  }, [initialPageResponse?.data?.data?.timelineData])

  const handleMap = useGetHandlerMap(ganttConfig!, path)

  const handleUpdate = async (barIds: Array<string>, objectCode: string) => {
    const requests = barIds.map(objectId =>
      getGantBox({
        configId,
        objectId,
        objectCode,
        filters,
      })
    )
    const result = await Promise.all<DataX[]>(requests)

    const dataX: DataX[] = []
    const dataBacklog: DataX[] = []

    result?.forEach(item => {
      if (!item.data) {
        return
      }

      if (statelessResourceKeyFields.current?.some(key => item.data[key])) {
        dataX.push(item.data)
      } else {
        dataBacklog.push(item.data)
      }
    })

    setDynamicDataX(dataX)
    setDynamicDataBacklog(dataBacklog)
  }

  const handleDelete = (barIds: Array<string>) => {
    setDeleteIds(barIds)
  }

  const handleSortYChange = useCallback(
    (sortParam: GridSortModel) => {
      if (!isInited) {
        return
      }

      const sortModel = sortParam.filter(sort => sort.sort && sort.field)
      const sortQuery = sortModel.map(sortItem => `${sortItem.field},${sortItem.sort}`)

      setIsInited(false)
      setSort(sortQuery)
      setSortModel(sortModel)
      setObjectToSort(resourceId ?? '')
      setNextVerticalPage(0)
    },
    [resourceConfig?.columns, nextTime?.end, nextTime?.start, sort, isInited]
  )

  const handleYScroll = useCallback((params: ScrollData) => {
    // NOTE при рабочей подписке переместить код ниже в условие
    // if (params.isEnd) { }

    //params.currentPage в ганте отсчёт от 1 тут от 0
    params.currentPage && setNextVerticalPage(params.currentPage)

    params.screenSize && setNextPageSize(params.screenSize)
  }, [])

  const handleContextMenuItemClick = useCallback(
    (params: ActionData) => {
      const menuItem: ContextMenuAction = params?.metaData?.menuItem
      const command = menuItem?.command
      const commands = menuItem?.commands
      const additionalData = menuItem?.additionalData

      if (commands) {
        handleMap.onContextMenuItemClick?.(params)
      }

      if (command) {
        switch (command) {
          case COMMAND.SORT: {
            const sort = additionalData?.order
            const field = params.data?.header?.column

            sort && field && handleSortYChange([{ sort, field }])

            return
          }
          case COMMAND.UNSORT: {
            setIsInited(false)
            setDefaultSort()

            return
          }
          default:
            handleMap.onContextMenuItemClick?.(params)
        }
      }
    },
    [
      ganttConfig?.id,
      resourceId,
      handleSortYChange,
      handleMap.onContextMenuItemClick,
      setDefaultSort,
    ]
  )

  const handleScreenSize = useCallback((screenSize: number) => {
    setNextPageSize(screenSize)
  }, [])

  const handleChangeDates = useCallback(
    (params: ReqDateEdges) => {
      if (params?.start && params?.end && !isFetching) {
        setNextTime(prevState => ({
          start:
            prevState?.end && moment(params.start).isBetween(prevState?.start, prevState?.end)
              ? prevState?.end
              : params.start,
          end:
            prevState?.start && moment(params.end).isBetween(prevState?.start, prevState?.end)
              ? prevState?.start
              : params.end,
        }))
      }
    },
    [isFetching]
  )

  useEffect(() => {
    dispatch(setTitle(title))

    return () => {
      dispatch(resetTitle())
    }
  }, [title])

  useEffect(() => {
    return () => resetState()
  }, [configId])

  useEffect(() => {
    if (
      isFulfilled(initialPageResponse) &&
      initialPageResponse?.data?.data?.resourceData &&
      !isUndefined(sort)
    ) {
      setIsInited(true)
      setCurrentTime(nextTime)
    }
  }, [initialPageResponse])

  useEffect(() => {
    if (isFulfilled(initBacklogResponse) && initBacklogResponse?.data && isInited) {
      setIsInitedBacklog(true)
    }
  }, [isInited, initBacklogResponse])

  useEffect(() => {
    if (isFulfilled(verticalPageResponse) && verticalPageResponse?.data?.data?.resourceData) {
      const dataX: DataX[] = []
      const dataBacklog: DataX[] = []

      verticalPageResponse?.data?.data?.timelineData?.forEach(
        item => {
          if (resourceKeyFields?.some(key => key in item)) {
            dataX.push(item)
          } else {
            dataBacklog.push(item)
          }
        } // temp
      )
      setDynamicDataY(verticalPageResponse?.data?.data?.resourceData)
      setDynamicDataX(dataX) //todo сделать вывод разницы network даты и отфильтрованных
      setDynamicDataBacklog(dataBacklog)
      // setCurrentVerticalPage(verticalPageResponse?.originalArgs?.params?.page)
      setCurrentVerticalPage(nextVerticalPage)
    }
  }, [verticalPageResponse])

  useEffect(() => {
    if (isFulfilled(horizontalPageResponse) && horizontalPageResponse?.data?.data?.timelineData) {
      const dataX: DataX[] = []
      const dataBacklog: DataX[] = []

      horizontalPageResponse?.data?.data?.timelineData?.forEach(
        item => {
          if (resourceKeyFields?.some(key => key in item)) {
            dataX.push(item)
          } else {
            dataBacklog.push(item)
          }
        } // temp
      )

      setDynamicDataY(horizontalPageResponse?.data?.data?.resourceData)
      setDynamicDataX(dataX)
      setDynamicDataBacklog(dataBacklog)
      // setCurrentStartTime(horizontalPageResponse?.originalArgs?.params?.startTime)
      // setCurrentEndTime(horizontalPageResponse?.originalArgs?.params?.stopTime)
      setCurrentTime(nextTime)
    }
  }, [horizontalPageResponse])

  useEffect(() => {
    if (isFulfilled(dynamicBacklogResponse) && dynamicBacklogResponse?.data) {
      setDynamicDataBacklog(dynamicBacklogResponse?.data)
    }
  }, [dynamicBacklogResponse])

  useEffect(() => {
    setIsFetching(
      initialPageResponse?.isFetching ||
        verticalPageResponse?.isFetching ||
        horizontalPageResponse?.isFetching
    )
  }, [
    initialPageResponse?.isFetching,
    verticalPageResponse?.isFetching,
    horizontalPageResponse?.isFetching,
  ])

  useEffect(() => {
    setDefaultSort()
  }, [configResponse?.data?.sort?.value])

  useEffect(() => {
    if (!resourceConfig) {
      return
    }

    const filters: Record<string, string> = {}

    const errorInfo: TimelineItem['filter'][] = []

    const setValue = <T extends Pick<TimelineItem, 'data' | 'filter'>>(item: T) => {
      if (item?.filter && item?.data?.id) {
        errorInfo.push(item.filter)

        const value = getParamsStringSafe(item.filter)

        value && (filters[item.data.id] = value)
      }
    }

    try {
      timelineConfig?.forEach(setValue)

      setValue(resourceConfig)
      errorInfo.length = 0
    } catch (e) {
      dispatch(showMessage({ type: 'error', text: t('configuredGantt.incorrectDefFilters') }))
      console.error('Bad Config Filter', errorInfo, e)
    }

    const queryFilters = encodeURIComponent(
      Object.entries(filters)
        .map(([key, value]) => `${key};${value}`)
        .join()
    )

    !errorInfo.length && setFilters(queryFilters)
  }, [resourceConfig?.filter, timelineConfig?.filter])

  //local subscription for execute command
  useEffect(() => {
    function handler(e: CustomEvent<DetailSelfUpdate>) {
      const { objectId, objectCode } = e.detail || {}
      objectId && objectCode && handleUpdate([objectId], objectCode)
    }

    window.addEventListener(GanttEvents.SELF_UPDATED, handler as any)

    return () => {
      window.removeEventListener(GanttEvents.SELF_UPDATED, handler as any)
    }
  }, [])

  const subscriptionId = useMemo(() => {
    const id = initialPageResponse?.data?.data?.id

    return id ? `/gantt_id/${id}` : ''
  }, [initialPageResponse?.data?.data?.id])

  // NOTE активна, не работает (со стороны бэка)
  useSubscription(subscriptionId, (msg: IMessage) => {
    console.info('CHECK GANTT Subscription', subscriptionId, msg)
    try {
      const body: MessageGanttBody = JSON.parse(msg.body)
      const { eventName, objectCode, timeLineIds } = body

      switch (eventName) {
        case EVENT_NAME_OBJECT_MESSAGE.DELETE: {
          handleDelete(timeLineIds)
          break
        }
        default: {
          handleUpdate(timeLineIds, objectCode)
          break
        }
      }
    } catch (e) {
      console.error('bad msg.body', msg, e)
      dispatch(
        showMessage({ type: 'error', text: t('configuredGantt.incorrectSubscriptionMessage') })
      )
    }
  })

  const handleDoubleCellClick = useCallback(
    (data: ActionData) => {
      handleMap.onDoubleCellClick?.forEach(fun => fun?.(data))
    },
    [handleMap.onDoubleCellClick]
  )

  const handleDoubleRowXClick = useCallback(
    (data: ActionData) => {
      handleMap.onDoubleRowXClick?.forEach(fun => fun?.(data))
    },
    [handleMap.onDoubleRowXClick]
  )

  const handleDoubleRowYClick = useCallback(
    (data: ActionData) => {
      handleMap.onDoubleRowYClick?.forEach(fun => fun?.(data))
    },
    [handleMap.onDoubleRowYClick]
  )

  const handleDoubleBoxClick = useCallback(
    (data: ActionData) => {
      handleMap.onDoubleBoxClick?.forEach(fun => fun?.(data))
    },
    [handleMap.onDoubleBoxClick]
  )

  const handleBoxResizeMemo = useCallback(
    (data: ActionData) => {
      handleMap.onBoxResize?.forEach(fun => fun?.(data))
    },
    [handleMap.onBoxResize]
  )

  const handleBoxMoveMemo = useCallback(
    (data: ActionData) => {
      handleMap.onBoxMove?.forEach(fun => fun?.(data))
    },
    [handleMap.onBoxMove]
  )

  const handleMessage = (message: string) => {
    dispatch(showMessage({ type: 'error', text: message }))
  }

  const handleBoxMove = handleMap?.onBoxMove?.length ? handleBoxMoveMemo : undefined
  const handleBoxResize = handleMap?.onBoxResize?.length ? handleBoxResizeMemo : undefined

  const { availableResizes, availableMoves } = useGetAvailableMovesAndResizes(ganttConfig)

  if (isUndefined(configId)) {
    return null
  }

  return (
    <Suspense fallback={<LinearProgress />}>
      <GantChartWithProvider
        key={`${configId}-${enterDate}`}
        availableMoves={availableMoves}
        availableResizes={availableResizes}
        chartId={configId}
        config={preparedConfig!}
        deleteIds={deleteIds}
        dynamicDataBacklog={dynamicDataBacklog}
        dynamicDataX={dynamicDataX}
        dynamicDataY={dynamicDataY}
        initDataBacklog={initBacklogResponse?.data || []}
        initDataX={initDataX || []}
        initDataY={initialPageResponse?.data?.data?.resourceData || []}
        loadingBacklog={initBacklogResponse?.isFetching}
        loadingConfig={configResponse?.isFetching}
        loadingData={isFetching}
        sortModelY={sortModel}
        theme={theme}
        timezone={timezone}
        onBoxMoved={handleBoxMove}
        onBoxResized={handleBoxResize}
        onChangeDates={handleChangeDates}
        onContextMenuItemClick={handleContextMenuItemClick}
        onDoubleBoxClick={handleDoubleBoxClick}
        onDoubleCellClick={handleDoubleCellClick}
        onDoubleRowXClick={handleDoubleRowXClick}
        onDoubleRowYClick={handleDoubleRowYClick}
        onMessage={handleMessage}
        onScreenSize={handleScreenSize}
        onSortYChange={handleSortYChange}
        onYScroll={handleYScroll}
      />
    </Suspense>
  )
}
