import { FC } from 'react'
import { FieldErrors } from 'react-hook-form'
import { StepLabel } from '@mui/material'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit'

import { getIsStepFailed } from './utils'

interface IProps {
  label: string
  errors: FieldErrors<GanttFormValues>
  onClick: () => void
}

export const StepLabelWrap: FC<IProps> = ({ label, errors, onClick }) => {
  const isStepFailed = getIsStepFailed(label, errors)

  return (
    <StepLabel
      // error={isStepFailed}
      icon={' '}
      sx={{ cursor: 'pointer' }}
      // optional={
      //   isStepFailed && <StepError errors={getStepErrorFields(label, errors)} label={label} />
      // }
      onClick={onClick}
    >
      {label}
    </StepLabel>
  )
}
