import { useEffect, useState } from 'react'
import moment from 'moment'
import { Box, Skeleton, Typography } from '@mui/material'

import { useAppSelector } from '@hooks'
import { DRAWER_WIDTH } from '@constants'

const getTime = () => moment().format('DD.MM.YYYY HH:mm')

interface IProps {
  hasSidebar: boolean
}

export const AppFooter = (props: IProps) => {
  const { title } = useAppSelector(state => state.appFooter)

  const [now, setNow] = useState<string>()
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    !isLoading && setInterval(() => setNow(getTime()), 60_000)
  }, [isLoading])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      setNow(getTime())
    }, 500) // для установки кастомной таймзоны в роуте
  }, [])

  return (
    <Box
      alignItems='center'
      bgcolor={theme => theme.palette.background.footer}
      display='flex'
      height={30}
      px={2}
    >
      <Box width={props.hasSidebar ? DRAWER_WIDTH : 0} />
      <Box display='flex' justifyContent='start'>
        <Typography variant='subtitle1'>{title}</Typography>
      </Box>
      <Box flex={1} />
      <Box display='flex' justifyContent='end'>
        {isLoading && <Skeleton height={20} variant='rounded' width={150} />}
        {!isLoading && <span title={moment().format('Z')}>{now}</span>}
      </Box>
    </Box>
  )
}
