import { useCallback } from 'react'
import { get } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { ActionData } from '@microservices/gantt-react-component'

import { openFormDialog } from '@redux/reducers/dialogWindowManager.reducer'
import { createHistoryByView } from '@redux/reducers/formHistory.reducer'

import { useAppDispatch } from '@hooks'
import { getInitialPositionWindow, getParamsStringSafe, getVerifyFormCode } from '@helpers'
import { ENTITY, FORM_TYPE } from '@constants'
import { DIALOG_WINDOW_TYPE, MouseEventWithView } from '@types'

import { AxisDefinedData, GanttActionType, GanttFormValues } from '../types'

export const useOpenForm = (ganttConfig: GanttFormValues, path: string) => {
  const dispatch = useAppDispatch()

  return useCallback(
    (action: GanttActionType, actionData: ActionData, definedData: AxisDefinedData) => {
      const { target: _, ...params } = action
      const formId = get(action, 'actionField.form.id')
      const verifiedFormCode = getVerifyFormCode({
        eventCode: action.eventCode,
        row: actionData?.data,
        actions: [
          {
            ...params,
            objectFormCode: formId,
            params: getParamsStringSafe(action.actionField),
          },
        ],
      })

      const dialogId = uuid()

      if (verifiedFormCode) {
        dispatch(
          createHistoryByView({
            id: dialogId,
            form: {
              formCode: verifiedFormCode,
              objectCode: definedData.objectCode,
              objectId: definedData.objectId,
              event: FORM_TYPE.VIEW,
              isWindow: true,
              title: verifiedFormCode,
              id: dialogId,
            },
            view: {
              objectCode: definedData.objectCode,
              title: ganttConfig.title || '',
              viewPath: path,
              id: dialogId,
            },
          })
        )

        dispatch(
          openFormDialog({
            id: dialogId,
            parentDialogId: null,
            type: DIALOG_WINDOW_TYPE.FORM,
            title: null,
            meta: {
              formId,
              formCode: verifiedFormCode,
              viewCode: ganttConfig?.code,
              path,
              event: FORM_TYPE.VIEW,
              globalId: dialogId,
              entityType: ENTITY.GANTT_CHART,
              viewId: ganttConfig.id,
              ...definedData,
            },
            initialPosition: getInitialPositionWindow(
              actionData?.metaData?.event as MouseEventWithView
            ),
          })
        )
      }

      return true
    },
    [ganttConfig?.code, path]
  )
}
