import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import last from 'lodash/last'

import { transformTextParamsData } from '@pages/Parameters/helpers'

import { GetTextParamsHandle } from '@components/TextSettings'

import { usePrompt, useYupValidationResolver } from '@hooks'
import { getColumnParametersFromArray } from '@helpers'

import { GanttColumnForm, PageContext } from '@gantt/components/GanttCreateOrEdit'

import { ganttColumnFormSchema } from '@validations'

import { getGanttColumnDefaultValue } from '../helpers/getGanttColumnDefaultValue'

import { useHandlers } from './useHandlers'
import { useInputs } from './useInputs'

export const useAddGanttFieldDialog = () => {
  const defaultValues: GanttColumnForm = getGanttColumnDefaultValue()
  const resolver = useYupValidationResolver(ganttColumnFormSchema)
  const methods = useForm<GanttColumnForm>({
    defaultValues,
    // resolver,
  })
  const columnHeaderSettingsRef = useRef<GetTextParamsHandle>(null)
  const columnTextSettingsRef = useRef<GetTextParamsHandle>(null)
  const [isLoading, setLoading] = useState(true)

  const {
    handleSubmit,
    reset,
    formState: { isDirty: isDirtyBasicParameters },
    watch,
  } = methods

  const watchPinning = watch('pinning')
  const watchUseParameters = watch('useParameters')
  const watchPathArray = watch('field.field.pathArray')

  const valueType = last(watchPathArray)?.valueType || null

  const {
    currentRow, //todo проработать тип
    onSetModalProperty,
    objectCodeResource: viewObjectCode,
    bindingValuesColumns,
  } = useContext(PageContext)

  const { headerProperties, textProperties } = getColumnParametersFromArray(
    currentRow?.parameters || []
  )

  const initialColumnHeaderStyles = useMemo(() => {
    const arr: any = headerProperties
      ? Object.entries(headerProperties).map(([key, value]) => ({ key, value }))
      : []

    return transformTextParamsData(arr)
  }, [headerProperties])

  const initialColumnTextStyles = useMemo(() => {
    const arr: any = textProperties
      ? Object.entries(textProperties).map(([key, value]) => ({ key, value, num: 1, id: 1 }))
      : []

    return transformTextParamsData(arr)
  }, [textProperties])

  const bindTypeOptions = useMemo(
    () => bindingValuesColumns?.map(({ id, code: name }) => ({ id, name })),
    [bindingValuesColumns]
  )

  useEffect(() => {
    if (currentRow) {
      reset({
        ...currentRow,
        valueType,
        pinning: Boolean(currentRow.pinnedColumn),
        useParameters: Boolean(currentRow?.useLocalParameters),
      })

      setLoading(false)
    }
  }, [currentRow])

  useEffect(() => {
    onSetModalProperty?.('column')
  }, [])

  const { state, handlers } = useHandlers({
    watchUseParameters,
    isDirty: isDirtyBasicParameters,
    handleSubmit,
    viewObjectCode: viewObjectCode || '',
    columnHeaderSettingsRef,
    columnTextSettingsRef,
    initialColumnHeaderStyles,
    initialColumnTextStyles,
    valueType,
  })

  const { isTextParamsDirty, isShowResetConfirmModal } = state
  const {
    handleSave,
    handleTextExternalDirty,
    handleHeaderExternalDirty,
    handleSetValuesColumnStyles,
    handleClickReset,
    handleResetParams,
    handleSetShowResetConfirmModal,
  } = handlers

  const isDirty = isDirtyBasicParameters || isTextParamsDirty

  usePrompt({ when: isDirty })

  const { columnInputs, otherColumnInputs } = useInputs({
    bindTypeOptions: bindTypeOptions || [],
    valueType,
    watchPinning,
    currentRow,
  })

  return {
    isDirty,
    handleSave,
    isLoading,
    methods,
    columnInputs,
    otherColumnInputs,
    columnHeaderSettingsRef,
    columnTextSettingsRef,
    initialColumnHeaderStyles,
    initialColumnTextStyles,
    watchUseParameters,
    isShowResetConfirmModal,
    handleTextExternalDirty,
    handleHeaderExternalDirty,
    handleSetValuesColumnStyles,
    handleClickReset,
    handleResetParams,
    handleSetShowResetConfirmModal,
    bindTypeOptions,
  }
}
