import { useCallback, useEffect } from 'react'
import { ActionData, DataXKeys } from '@microservices/gantt-react-component'

import { processResponse } from '@pages/ConfiduredGantt/utils'

import {
  useMoveGanttBacklogBoxMutation,
  useMoveGanttBoxMutation,
  useResizeGanttBoxMutation,
} from '@redux/api/ganttChart.api'

import { AxisDefinedData, GanttActionType } from '../types'

export const useMutateObject = () => {
  const [moveGantBacklogBox, moveBacklogBoxResponse] = useMoveGanttBacklogBoxMutation()
  const [resizeGantBox, resizeBoxResponse] = useResizeGanttBoxMutation()
  const [moveGantBox, moveBoxResponse] = useMoveGanttBoxMutation()

  useEffect(() => {
    processResponse(moveBacklogBoxResponse)
  }, [moveBacklogBoxResponse?.status])

  useEffect(() => {
    processResponse(resizeBoxResponse)
  }, [resizeBoxResponse?.status])

  useEffect(() => {
    processResponse(moveBoxResponse)
  }, [moveBoxResponse?.status])

  return useCallback(
    (action: GanttActionType, actionData: ActionData, definedData: AxisDefinedData) => {
      if (actionData.changes?.resource) {
        const resourceIdValue = actionData.changes.resource.resourceKeyField || ''
        const resourceIdName = actionData.changes.resource.link.resourceKeyField

        if (actionData.metaData?.target === DataXKeys.TimelineData) {
          moveGantBox({
            ...definedData,
            resourceIdValue,
            resourceIdName,
          })
        } else {
          moveGantBacklogBox({
            ...definedData,
            resourceIdName,
          })
        }
      }

      if (actionData.changes?.datetimeStart || actionData.changes?.datetimeEnd) {
        const timeValues: Record<string, string> = {}

        if (actionData.changes.datetimeStart?.value) {
          timeValues[String(actionData.changes.datetimeStart?.field.field?.field)] =
            actionData.changes.datetimeStart?.value
        }

        if (actionData.changes.datetimeEnd?.value) {
          timeValues[String(actionData.changes.datetimeEnd?.field.field?.field)] =
            actionData.changes.datetimeEnd?.value
        }

        resizeGantBox({
          ...definedData,
          timeValues,
        })
      }
    },
    []
  )
}
